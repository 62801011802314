import { useEffect } from "react";

const NEAR_TO_BOTTOM = 60;

// хук для загрузки большего числа данных
// кидаем функцию загрузчик и реф на контейнер, где лежат данные
// когда юзер подкрутит достаточно близко (NEAR_TO_BOTTOM) к концу
// контейнера то вызываем функцию
export const useFetchMore = (fetchMore, containerRef) => {
  useEffect(() => {
    const handler = () => {
      if (containerRef.current) {
        const wantToLoadMore = containerRef.current.clientHeight - NEAR_TO_BOTTOM <
          window.pageYOffset + window.innerHeight - containerRef.current.offsetTop;
        if (wantToLoadMore) {
          fetchMore();
        }
      }
    };

    window.addEventListener("scroll", handler);
    return () => window.removeEventListener("scroll", handler);
  }, [fetchMore, containerRef]);
};
