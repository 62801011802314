import React, { useEffect } from 'react'
import './Header.css';
import { useGlobalState } from "../../../state/state";
import { setCountersState } from '../../../state/mainPageState';
import { getCountersAction } from "../../../api/actions";

const Header = () => {
  const [{ usersTotal, stakingHolders, totalStakingAmount }] = useGlobalState('counters');

  useEffect(() => {
    if (!usersTotal && !stakingHolders && !totalStakingAmount) {
      getCountersAction()
        .then(setCountersState)
        .catch(error => {
          console.error('error', error);
        });
    }
  }, []);

  return (
    <div className="header-container">
      <div>
        <p className="col-name">Users total</p>
        <p className="row-data">{ usersTotal }</p>
      </div>
      <div>
        <p className="col-name">Staking holders</p>
        <p className="row-data">{ stakingHolders }</p>
      </div>
      <div>
        <p className="col-name">Total staking amount</p>
        <p className="row-data">{ totalStakingAmount }</p>
      </div>
    </div>
  )
};

export default Header;
