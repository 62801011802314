import React from "react";
import './CustomLink.css';

const CustomLink = ({ text, href, isEtherscan = true, prefix = 'address' }) => {
  const baseUrl = isEtherscan ? `https://etherscan.io/${ prefix }/` : '';
  return (
    <a href={ baseUrl + href } target="_blank" rel="noopener noreferrer">
      { text }
    </a>
  )
};

export default CustomLink;
