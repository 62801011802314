import React, { useEffect, useMemo, useRef, useState } from 'react';
import Section from '../../components/Section/Section';
import PageWrapper from '../../components/PageWrapper/PageWrapper';
import Table from '../../components/Table/Table';
import Title from '../../components/Title/Title';
import { useGlobalState } from "../../state/state";
import { setCarOrderState, changeCarOrderUserFiledState, fetchMoreCarOrderUsersState, setPreviousValue, clearCarOrdersOffset } from '../../state/carOrderState';
import { getCarOrderAction, updateCarOrderUsersAction } from "../../api/actions";
import { carOrdersConfig } from "../../constants/tablesConfig";
import { carOrderStatuses } from "../../constants/carOrderStatuses";
import { buttonColors } from "../../constants/buttons";
import { useFetchMore } from "../../hooks/useFetchMore";
import { openPopup } from '../../state/popupState';
import { modalStatus } from "../../constants/modalStatus";
import CarOrdersSearch from './Search/CarOrdersSearch';
import { formatDate, formateText } from '../../functions/functios';
 
const CarOrder = () => {
  const [{ usersList, limit, offset, search_string }] = useGlobalState('carOrderUsers');
  const [loadingButtons, setLoadingButtons] = useState({});
 
  const isLoadingUsers = useRef(false);
  const isAllUsersLoaded = useRef(false);
  const containerRef = useRef();
 
  const [statValue, setStatValue] = useState();

  const [noUsersSearched, setNoUsersSearched] = useState(false);
 
  const data = null;
 
  const setLoadingToButton = (id, loading) => {
    setLoadingButtons(prevLoading => ({ ...prevLoading, [id]: loading }))
  };
 
  const loadUsers = newOffset => {
    getCarOrderAction(data, { limit, offset: newOffset, search_string })
      .then(response => {
        isLoadingUsers.current = false;
        setCarOrderState(response);
 
        if (!response?.result.length) {
          isAllUsersLoaded.current = true;
        }
      })
      .catch(error => {
        isLoadingUsers.current = false;
        console.error('error', error);
      });
  };
 
  const loadMoreUsers = () => {
    if (!isLoadingUsers.current && !isAllUsersLoaded.current) {
      isLoadingUsers.current = true;
      fetchMoreCarOrderUsersState(loadUsers);
    }
  };
 
  useFetchMore(loadMoreUsers, containerRef);
 
  const handleFocusStatus = (event) =>{
    setStatValue(event.target.value)
  }
 
  const handleChangeStatus = (event, id) => {
    changeCarOrderUserFiledState(id, 'status', event.target.value);
  };
  const handleChangeInsurancePrice = (event, id ) => {
    changeCarOrderUserFiledState(id, 'insurance_price', event.target.value, 'wdx', 'rub');
  };
 
  const handleClickSave = (event, id) => {
    const currentUser = usersList.find(user => user.id === id);
    if (currentUser) {
      const { insurance_price:{wdx}, status } = currentUser;
      const userData = { insurance_price: wdx || null,status };
      setLoadingToButton(id, true);
      updateCarOrderUsersAction(id, userData)
        .then(response => {
            setLoadingToButton(id, false);
            openPopup({ message: response.message, status: modalStatus.success, link: response.tx_hash });
        })
        .catch(error => {
          console.error('error', error);
          setLoadingToButton(id, false);
          setPreviousValue(id, statValue)
          openPopup({ message: error.message || error.data, status: modalStatus.error, link: error.tx_hash });
        });
    }
  };
 
  const mapUsersToTable = useMemo(() => () =>{
    return usersList.map(user => {
      return [
        { value: user.id },
        { value: user.user.id },
        { value: formatDate(user.created_time) },
        { value: user.user.email },
        { value: formateText(user.prepayment_amount.wdx, user.prepayment_amount.rub)},
        { value: formateText(user.price.wdx, user.price.rub)},
        { onChange:  event => handleChangeInsurancePrice(event, user.id),convertValue: user.insurance_price.rub, value: user.insurance_price.wdx },
        { value: formateText(user.balance.wdx, user.balance.rub)},
        { value: formateText(user.charged.wdx, user.charged.rub)},
        { options: carOrderStatuses, value:user.status, onChange: event => handleChangeStatus(event, user.id), onFocus: event => handleFocusStatus(event)},
        {
          text: 'Save',
          onClick:  event => handleClickSave(event, user.id),
          color: buttonColors.green,
          isDisabled: loadingButtons[user.id],
        }
      ]
    })
  }, [usersList, handleChangeStatus, handleChangeInsurancePrice, handleClickSave, loadingButtons]);
 
  useEffect(() => {
    if (!usersList) {
      loadUsers(offset);
    }
    clearCarOrdersOffset()
  }, []);
 
  const tableValues = usersList ? mapUsersToTable() : [];

  return (
    <PageWrapper containerRef={ containerRef }>
      <Section><Title title="Car orders" /></Section>
      <Section><CarOrdersSearch setNoUsersSearched={setNoUsersSearched} /></Section>
      {noUsersSearched ?
        <div>По условиям поиска ничего не найдено</div>
        :
        <Table tableConfig={ carOrdersConfig } values={ tableValues } />
      }
    </PageWrapper>
  )
};
 
export default CarOrder