import React, { useState } from "react";
import Modal from 'react-modal';
import { useGlobalState } from "../../state/state";
import { changePricePopupFieldState, closeChangePricePopup } from '../../state/changePricePopupState';
import './ChangePricePopup.css';
import CustomLink from "../CustomLink/CustomLink";
import Input from "../Input/Input";
import { Button } from "../Button/Button";
import { getExchangeRatesAction, postWdxPriceAction } from "../../api/actions";
import { buttonColors, buttonSizes } from "../../constants/buttons";
import Section from "../Section/Section";
import { roundNumber } from "../../functions/functios";
 
const ChangePricePopup = () => {

  const [{ show, link, contract, balance, wdxPriceEth, wdxPriceUsdt, wdxPriceRub }] = useGlobalState('changePricePopup');

  const [showSucsesStatus, setShowSucsesStatus] = useState(false); 
  const [showErrorStatus, setShowErrorStatus] = useState(false);
  const [res, setRes] = useState();

  const [eth, setWdxEth] = useState('');
  const [usdt, setWdxUsdt] = useState('');
  const [rub, setWdxRub] = useState('');
  const [contractNum, setContractNum] = useState(link);

  const handleClickUpdatePrice = (event) => {
     const data = { price: wdxPriceEth, contract_number: link };
      postWdxPriceAction(data)
        .then(response => {
          setShowSucsesStatus(true)
          setShowErrorStatus(false)
          setRes(response.tx_hash)
        })
        .catch(error => {
          console.error('error', error);
          setShowErrorStatus(true)
          setShowSucsesStatus(false)
        });
    } 
    // !eth && !usdt && !rub
      if (contractNum !== link && link !== '') {
        setContractNum(link)
        getExchangeRatesAction(link)
        .then( response => {
          setWdxEth(roundNumber(response.result.wdx_eth));
          setWdxUsdt(roundNumber(response.result.eth_usdt));
          setWdxRub(roundNumber(response.result.usdt_rub));
          changePricePopupFieldState('wdxPriceEth',roundNumber(response.result.wdx_eth));
          changePricePopupFieldState('wdxPriceUsdt',roundNumber(response.result.eth_usdt * response.result.wdx_eth));
          changePricePopupFieldState('wdxPriceRub',roundNumber(response.result.eth_usdt * response.result.wdx_eth * response.result.usdt_rub));
        }
        )
        .catch(error => {
          console.error('error', error);
        });
      }  

  return (

        <Modal
        isOpen={ show }
        onRequestClose={ () => {
            closeChangePricePopup();
            setShowErrorStatus(false);
            setShowSucsesStatus(false);
          }
        }
        ariaHideApp={ false }
        className="modal"
        overlayClassName="modal-overlay"
        >
          <div className="modal-title">{contract}</div>
          <Section>
            <div className="section-children">
              <p className="section-firstItem">Balance:</p>
              <p className="section-secondItem balance">{balance}</p>
            </div>
          </Section>
          <Section>
            <div className="section-children">
              <p className="section-firstItem">Contract number:</p>
              <div className="section-secondItem">
                <CustomLink text={link} href={link}/>
              </div>  
            </div>
            <div className="section-wdx">
              <div>ETH</div><div>USDT</div><div>RUB</div>
            </div>
            <div className="section-wdx">
              <div>{roundNumber(eth)}</div><div>{roundNumber(usdt*eth)}</div><div>{roundNumber(usdt*eth*rub)}</div>
            </div>
            <div className="section-wdx">
              <Input 
                    value= {wdxPriceEth} 
                    onChange= { e =>{
                      changePricePopupFieldState('wdxPriceEth', e.target.value);
                      changePricePopupFieldState('wdxPriceUsdt',roundNumber(e.target.value * usdt));
                      changePricePopupFieldState('wdxPriceRub',roundNumber(e.target.value * usdt * rub));
                    }
                  }
              />
              <Input 
                    value= {wdxPriceUsdt} 
                    onChange= { e => {
                                  changePricePopupFieldState('wdxPriceEth',roundNumber(e.target.value/usdt));
                                  changePricePopupFieldState('wdxPriceUsdt',e.target.value);
                                  changePricePopupFieldState('wdxPriceRub',roundNumber(e.target.value *rub));
                                }
                              }
              />
              <Input 
                    value= {wdxPriceRub} 
                    onChange= { e => {
                                  changePricePopupFieldState('wdxPriceEth',roundNumber(e.target.value/(rub*eth)));
                                  changePricePopupFieldState('wdxPriceUsdt',roundNumber(e.target.value/rub));
                                  changePricePopupFieldState('wdxPriceRub',e.target.value);
                                }
                              }
              />
            </div>
            <div className="modal-priceBlock">
              <Button 
                    onClick={ event => handleClickUpdatePrice(event) } 
                    size={buttonSizes.large} 
                    text='Change price' 
                    color={buttonColors.blue}
              />
            </div>
          </Section>
          {showSucsesStatus && 
            <Section>
              <div className="section-children">
                <div className="section-firstItem modal-sucsess">Transaction: </div>
                <div className="section-secondItem">
                  <div className="modal-sucsess">
                    <CustomLink prefix = "tx" href={res} text={res} />
                  </div>
                </div>
              </div>
            </Section>
          }
          {showErrorStatus && 
            <Section>
              <div className="modal-error">Transaction error</div>
            </Section>
          }
          <span className="modal-close" onClick={ () => {
            closeChangePricePopup();
            setShowErrorStatus(false);
            setShowSucsesStatus(false);
          } }>
          x close
          </span> 
        </Modal>
  )
};
 
export default ChangePricePopup;