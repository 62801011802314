import React from 'react';

// приведение даты к формату дд:чч
export const formatDate = (date) => {
    let arr = date.split(' ');
    return <div>{arr[0]}<div>{arr[1].replace(/(..):(..):(.........)/, '$1:$2')}</div></div>
}
// представление цены в wdx и rub
export const formateText = (valWdx, valRub) => {
    return <div>{valWdx}<div>({valRub} &#8381;)</div></div>
}
// округление числа до 8 знаков после заяпятой
export const roundNumber = (num) =>  Number(num).toFixed(8)

// округление числа до 4 знаков после заяпятой
export const roundNum = (num) =>  Number(num).toFixed(4)