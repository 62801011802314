import React, { useEffect, useMemo, useRef, useState } from 'react';
import Section from '../../components/Section/Section';
import PageWrapper from '../../components/PageWrapper/PageWrapper';
import Table from '../../components/Table/Table';
import Title from '../../components/Title/Title';
import { useGlobalState } from "../../state/state";
import { setSettingsState, changeSettingsFiledState, fetchMoreSettingsState, removeSettingState } from '../../state/settingsState';
import { deleteSettingsAction, getSettingsAction, postSettingsAction } from "../../api/actions";
import { settingsConfig } from "../../constants/tablesConfig";
import { buttonColors } from "../../constants/buttons";
import { useFetchMore } from "../../hooks/useFetchMore";
import { openPopup } from '../../state/popupState';
import { modalStatus } from "../../constants/modalStatus";
import plusImg from './add.png';
import './Settings.css';
import { openAddNewKeyPopup } from '../../state/addNewKeyPopup';

const Settings = () => {
  const [{ settingsList, limit, offset }] = useGlobalState('settings');
  const [loadingButtons, setLoadingButtons] = useState({});
 
  const isLoadingSettings = useRef(false);
  const isAllSettingsLoaded = useRef(false);
  const containerRef = useRef();
  const data = null;
 
  const setLoadingToButton = (id, loading) => {
    setLoadingButtons(prevLoading => ({ ...prevLoading, [id]: loading }))
  };

  const loadSettings = newOffset => {
    getSettingsAction(data, { limit, offset: newOffset })
      .then(response => {
        isLoadingSettings.current = false;
        setSettingsState(response);
 
        if (response.length>0) {
          isAllSettingsLoaded.current = true;
        }
      })
      .catch(error => {
        isLoadingSettings.current = false;
        console.error('error', error);
      });
  };
 
  const loadMoreSettings = () => {
    if (!isLoadingSettings.current && !isAllSettingsLoaded.current) {
      isLoadingSettings.current = true;
      fetchMoreSettingsState(loadSettings);
    }
  };
 
  useFetchMore(loadMoreSettings, containerRef);

  const handleChangeValue = (event, id) => {
    changeSettingsFiledState(id, 'value', event.target.value);
  };
 
  const handleClickChange = (event, id) => {
    const currentSetting = settingsList.find(setting => setting.id === id);
    if (currentSetting) {
      const { key, value } = currentSetting;
      const settingData = { settings:[{[key] : value }]};
      setLoadingToButton(id, true);
      postSettingsAction(settingData)
      .then(response => {
        setLoadingToButton(id, false);
        openPopup({ message: 'Setting was updated successfully', status: modalStatus.success});
      })
      .catch(error => {
        console.error('error', error);
        setLoadingToButton(id, false);
        openPopup({ message: error.message, status: modalStatus.error });
      });
    }
  };

  const handleClickRemove = (event, id) =>{
    const currentSetting = settingsList.find(setting => setting.id === id);
    if (currentSetting) {
      const { key } = currentSetting;
      const data = { key: key }
      setLoadingToButton(id, true);
      deleteSettingsAction(data)
      .then(response => {
        setLoadingToButton(id, false);
        removeSettingState(id);
      })
      .catch(error => {
        console.error('error', error);
        setLoadingToButton(id, false);
        openPopup({ message: 'Setting delete error', status: modalStatus.error });
      });
    }
  }
  const mapSettingsToTable = useMemo(() => () =>{
    return settingsList.map(settings => {
      return [
        { value: settings.key },
        { onChange:  event => handleChangeValue(event, settings.id), value:settings.value },
        {
            text: 'Change',
            onClick:  event => handleClickChange(event, settings.id),
            color: buttonColors.blue,
            isDisabled: loadingButtons[settings.id]
        },
        {
            text: 'Delete',
            onClick:  event => handleClickRemove(event, settings.id),
            color: buttonColors.red,
            isDisabled: loadingButtons[settings.id],
          }
      ]
    })
  }, [settingsList, handleChangeValue, handleClickChange, handleClickRemove, loadingButtons]);
 
  useEffect(() => {
    if (!settingsList) {
      loadSettings(offset);
    }
  }, []);
 
  const tableValues = settingsList ? mapSettingsToTable() : [];
 
  return (
    <PageWrapper containerRef={ containerRef }>
      <Section><Title title="Settings" /></Section>
      <Section>
        <div className="settings--add-new-key">
          <img src={plusImg} alt="plusImg" onClick={()=> openAddNewKeyPopup()}/>
          <span>Add new key</span>
        </div>        
      </Section>
      <div className="settings-table">
        <Table tableConfig={ settingsConfig } values={ tableValues } />
      </div>
    </PageWrapper>
  )
};
 
export default Settings