import React from "react";
import Modal from 'react-modal';
import { useGlobalState } from "../../state/state";
import { closePopup } from '../../state/popupState';
import './Popup.css';
import CustomLink from "../CustomLink/CustomLink";
import { modalStatus } from "../../constants/modalStatus";
import errorIcon from './error.png';
import successIcon from './success.png';
 
const messages = {
  [modalStatus.success]: 'Transactions successfully created',
  [modalStatus.error]: 'Transaction creation error',
};
 
const icons = {
  [modalStatus.success]: successIcon,
  [modalStatus.error]: errorIcon,
};
 
const Popup = () => {
  const [{ show, status, message, link }] = useGlobalState('popup');
 
  return (
       
        <Modal
        isOpen={ show }
        onRequestClose={ closePopup }
        ariaHideApp={ false }
        className="modal"
        overlayClassName="modal-overlay"
        >
          <img src={ icons[status] } alt='error'/>
          <p className="modal-message">{ message || messages[status] }</p>
          { link && <CustomLink text={ link } href={ link } prefix="tx" /> }
          <span className="modal-close" onClick={ closePopup }>x close</span>
        </Modal>
  )
};
 
export default Popup;