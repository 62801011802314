export const tableCellTypes = {
  text: 'text',
  link: 'link',
  input: 'input',
  select: 'select',
  checkbox: 'checkbox',
  button: 'button'     
};
 
export const tableUsersConfig = [
  { title: 'Id', type: tableCellTypes.text },
  { title: 'Username', type: tableCellTypes.text },
  { title: 'Wallet', type: tableCellTypes.link },
  { title: 'User status', type: tableCellTypes.select },
  { title: 'Referer ID', type: tableCellTypes.input },
  { title: 'WDX withdrawal', type: tableCellTypes.checkbox },
  { title: 'Action', type: tableCellTypes.button },
];
 
export const tableTransactionsConfig = [
  { title: 'Contract number', type: tableCellTypes.link },
  { title: 'Contract details', type: tableCellTypes.button },
  { title: 'Balance', type: tableCellTypes.text },
  { title: 'Transaction', type: tableCellTypes.button },
];
 
export const carOrdersConfig = [
  {title: 'Order Id', type: tableCellTypes.text},
  {title: 'User Id', type: tableCellTypes.text},
  {title: 'Created', type: tableCellTypes.text},
  {title: 'Email', type: tableCellTypes.text},
  {title: 'Prepayment', type: tableCellTypes.text},
  {title: 'Car price', type: tableCellTypes.text},
  {title: 'Insurance price', type: tableCellTypes.input},
  {title: 'Balance', type: tableCellTypes.text},
  {title: 'Сharged', type: tableCellTypes.text},
  {title: 'Status', type: tableCellTypes.select},
  {title: 'Action', type: tableCellTypes.button},
]

export const settingsConfig = [
  {title: 'Key', type: tableCellTypes.text},
  {title: 'Value', type: tableCellTypes.input},
  {title: 'Change value', type: tableCellTypes.button},
  {title: 'Action', type: tableCellTypes.button},
]