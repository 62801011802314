export const api = {
  BASE_API_URL: process.env.REACT_APP_BASE_URL || "https://api.wordlex.site/",
  getUsers: '/admin/user/',
  login: '/login',
  getCounters: '/admin/get_stats',
  updateUser: id => `/admin/user/${ id }`,
  searchUsers: '/admin/user/',
  getContracts: '/admin/get_contracts',
  getContract: url => `/admin/${ url }`,
  getCarOrders: '/admin/car_order',
  searchCarOrders: '/admin/car_order',
  updateCarOrderStatus: id => `/admin/car_order_status/${ id }`,
  postWdxPrice: `/admin/update_wdx_eth_price`,
  getSettings: '/admin/settings',
  postSettings: '/admin/settings',
  deleteSettings: '/admin/settings',
  getExchangeRates: url => `/admin/get_exchange_rates/${ url }`
};
