import axios from 'axios';
import { api } from './api';

class AxiosInstance {
	axiosInstance;

	constructor(token) {
		if (token) {
			this.axiosInstance = axios.create(({ headers: { Authorization: token } }));
		} else {
			this.axiosInstance = axios;
		}
	}

	request = (url, method, data , params) => {
		// return this.axiosInstance[method](`${ api.BASE_API_URL }${ url }`, data)
		const options = {
			method: method,
			data: data,
			url: `${api.BASE_API_URL}${url}`,
			params: params
		};
		return this.axiosInstance(options)
			.then(response => response.data)
			.catch(error => {
				if (error.response.status === 401) {
					this.removeToken();
				}
				throw error.response;
			});
	};

	setToken = token => {
		const newToken = `JWT ${token}`;
		this.axiosInstance = axios.create(({ headers: { Authorization: newToken } }));
		localStorage.setItem('jwt_token', newToken);
	};

	removeToken = () => {
		this.axios = axios;
		localStorage.removeItem('jwt_token');
	};

	get = (url, data, params) => this.request(url, 'get', data, params);
	post = (url, data) => this.request(url, 'post', data);
	put = (url, data) => this.request(url, 'put', data);
	delete = (url, data) => this.request(url, 'delete', data);
}

export const apiTools = new AxiosInstance(localStorage.getItem('jwt_token'));
