import React from "react";
import './Checkbox.css';

const Checkbox = ({ onChange, checked = false }) => {
  return (
    <input type="checkbox" onChange={ onChange } checked={ checked } />
  )
};

export default Checkbox;
