import React, { useState } from "react";
import './Login.css';
import Title from "../../components/Title/Title";
import Input from "../../components/Input/Input";
import { Button } from "../../components/Button/Button";
import { buttonColors } from "../../constants/buttons";
import Section from "../../components/Section/Section";
import { signInAction } from "../../api/actions";
import { useHistory } from "react-router-dom";
import { setAuthState } from "../../state/authState";
import { routes } from "../../constants/routes";

const Login = () => {
  const [login, setLogin] = useState('');
  const [loginError, setLoginError] = useState(false);
  const [password, setPassword] = useState('');
  const [passwordError, setPasswordError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const history = useHistory();

  const handleChangeLogin = event => setLogin(event.target.value);

  const handleChangePassword = event => setPassword(event.target.value);

  const validateForm = () => {
    if (!login) {
      setLoginError(true);
      return false;
    }
    if (!password) {
      setPasswordError(true);
      return false;
    }
    setLoginError(false);
    setPasswordError(false);
    return true;
  };

  const handleErrorAuth = () => {
    setPasswordError(true);
    setLoginError(true);
    setLogin('');
    setPassword('');
    setIsLoading(false);
  };

  const handleSuccessAuth = response => {
    setIsLoading(false);
    setAuthState(response.token);
    history.push(routes.main);
  };

  const handleSubmit = () => {
    if (validateForm()) {
      setIsLoading(true);

      signInAction({ username: login, password })
        .then(handleSuccessAuth)
        .catch(handleErrorAuth)
    }
  };

  const handleFocusLogin = () => {
    if (loginError) {
      setLoginError(false);
    }
  };

  const handleFocusPassword = () => {
    if (passwordError) {
      setPasswordError(false);
    }
  };

  return (
      <div className="login-form">
        <Section><Title title="Sign in" /></Section>
        <div className="login-input">
          <Input
            value={ login }
            onChange={ handleChangeLogin }
            error={ loginError }
            placeholder="Login"
            onFocus={ handleFocusLogin }
          />
        </div>
        <div className="login-input">
          <Input
            value={ password }
            onChange={ handleChangePassword }
            error={ passwordError }
            placeholder="Password"
            type="password"
            onFocus={ handleFocusPassword }
          />
        </div>
        <Button onClick={ handleSubmit } color={ buttonColors.blue } isDisabled={ isLoading } text='Sign in' />
      </div>
  )
};

export default Login;
