import { roundNum, roundNumber } from '../functions/functios';
import { setGlobalState, USERS_LIMIT } from './state';
 
export const setCarOrderState = users => {
  const usersList = users?.result || [];
  const newUsersList = [...usersList.map(user => ({...user, 
    insurance_price:{
      wdx:roundNumber(user.insurance_price.wdx),
      rub: roundNum(user.insurance_price.rub)
    },
    price:{
      wdx:roundNumber(user.price.wdx),
      rub: roundNum(user.price.rub)
    }, 
    balance:{
      wdx:roundNumber(user.balance.wdx),
      rub: roundNum(user.balance.rub)
    },
    charged:{
      wdx:roundNumber(user.charged.wdx),
      rub: roundNum(user.charged.rub)
    },
    prepayment_amount:{
      wdx:roundNumber(user.prepayment_amount.wdx),
      rub: roundNum(user.prepayment_amount.rub)
    }
    })
  )]
  const newWdxRub = users?.wdx_rub || [];
  setGlobalState('carOrderUsers', oldUsers => ({
    ...oldUsers, usersList: [ ...(oldUsers.usersList || []), ...newUsersList ] , wdxRub: newWdxRub,
  }));
};
export const fetchMoreCarOrderUsersState = fetchMore => {
  setGlobalState('carOrderUsers', oldUsers => {
    const offset = oldUsers.offset + USERS_LIMIT;
    fetchMore(offset);
    return { ...oldUsers, offset }
  });
};
  
export const changeCarOrderUserFiledState = (id, firstFieldName, value, secondFieldName, therdFieldName) => {
  setGlobalState('carOrderUsers', oldUsers => {
    const newUserList = [...(oldUsers.usersList || [])];
    const userIndex = newUserList.findIndex(user => user.id === id);
    const convertValue = roundNum(value * oldUsers.wdxRub)

    if (userIndex !== -1 && !secondFieldName ) {
      newUserList[userIndex] = { ...newUserList[userIndex], [firstFieldName]: value };
    }
    else if(userIndex !== -1 && secondFieldName) {
      newUserList[userIndex] = { ...newUserList[userIndex], [firstFieldName]: {[secondFieldName]: value, [therdFieldName]: convertValue}};
    }
  
    return { ...oldUsers, usersList: [...newUserList] }
    
  });
};
 
export const setSearchCarOrdersUsersState = users => {
  const usersList = users?.result || [];
  const newUsersList = [...usersList.map(user => ({...user, 
    insurance_price:{
      wdx:roundNumber(user.insurance_price.wdx),
      rub: roundNum(user.insurance_price.rub)
    },
    price:{
      wdx:roundNumber(user.price.wdx),
      rub: roundNum(user.price.rub)
    }, 
    balance:{
      wdx:roundNumber(user.balance.wdx),
      rub: roundNum(user.balance.rub)
    },
    charged:{
      wdx:roundNumber(user.charged.wdx),
      rub: roundNum(user.charged.rub)
    },
    prepayment_amount:{
      wdx:roundNumber(user.prepayment_amount.wdx),
      rub: roundNum(user.prepayment_amount.rub)
    }
    })
  )]
  setGlobalState('carOrderUsers', oldUsers => ({
    ...oldUsers, usersList: [ ...newUsersList ] 
  }));
};
  
export const setCarOrderSearchState = search => {
  setGlobalState('carOrderUsers', oldUsers => ({
    ...oldUsers,
    search_string: search || '',
  }));
};
 
export const setPreviousValue = (id, statValue) =>{
  
  setGlobalState('carOrderUsers', oldUsers => {
    const newUserList = [...(oldUsers.usersList || [])];
    const userIndex = newUserList.findIndex(user => user.id === id);
    
    if (userIndex !== -1) {
      newUserList[userIndex] = { ...newUserList[userIndex], 'status': statValue };
    }
  
    return { ...oldUsers, usersList: [...newUserList] }
    
  });
}
 
export const clearCarOrdersOffset = () =>{
  setGlobalState('carOrderUsers', oldUsers => {
    return { ...oldUsers, offset:0 }
  });
}