import { initialState, setGlobalState } from './state';

export const openChangePricePopup = data => {
    setGlobalState('changePricePopup', oldPopup => ({ ...oldPopup, show: true, ...data }));
};
  
export const closeChangePricePopup = () => {
    setGlobalState('changePricePopup', () => ({ ...initialState.changePricePopup }));
};

export const changePricePopupFieldState = (fieldName, value) => {
    setGlobalState('changePricePopup', oldState => {
      return { ...oldState, [fieldName]: value }  
    });
  };  
