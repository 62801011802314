import React from 'react';
import './Input.css'
 
const Input = ({
  placeholder, onChange, onFocus, type = 'text', error = false, value = '', convertValue
}) => (
    <div className='input-wrapper'>
      <input
        className={`input ${error ? 'input-error' : ''}`}
        type={type}
        onChange={onChange}
        placeholder={placeholder}
        value={value || ''}
        onFocus={onFocus}
      />
      {
        convertValue ? <span>({convertValue} &#8381;)</span> : ''
      }
    </div>
  );
 
export default Input;