import { setGlobalState, USERS_LIMIT } from './state';

export const setSettingsState = settings => {
  const settingsList = settings || [];
  setGlobalState('settings', oldSettings => ({
    ...oldSettings,
    settingsList: [ ...settingsList ],
  }));
};
export const fetchMoreSettingsState = fetchMore => {
  setGlobalState('settings', oldSettings => {
    const offset = oldSettings.offset + USERS_LIMIT;
    fetchMore(offset);
    return { ...oldSettings, offset }
  });
};
  
export const changeSettingsFiledState = (id, fieldName, value) => {
  setGlobalState('settings', oldSettings => {
    const newSettingsList = [...(oldSettings.settingsList || [])];
    const userIndex = newSettingsList.findIndex(user => user.id === id);
    
    if (userIndex !== -1) {
      newSettingsList[userIndex] = { ...newSettingsList[userIndex], [fieldName]: value };
    }
  
    return { ...oldSettings, settingsList: [...newSettingsList] }
    
  });
};

export const removeSettingState = id => {

  setGlobalState('settings', oldSettings => {
    const settingsList = [...(oldSettings.settingsList || [])];
    const settingIndex = settingsList.findIndex(setting => setting.id === id);
    if (settingIndex !== -1) {
      settingsList.splice(settingIndex, 1)
    }
    return { ...oldSettings, settingsList: [...settingsList] }
  });
};
