import { setGlobalState } from './state';

export const setUsersState = users => {
  const usersList = users || [];
  setGlobalState('users', oldUsers => ({
    ...oldUsers,
    usersList: [...usersList]
  }));

  // setGlobalState('users', oldUsers => {
  //   const newUserList = [ ...(oldUsers.usersList || []), ...usersList ];
  //   if (newUserList.length>150) {
  //     const aa = newUserList.slice(50)
  //     return { ...oldUsers, usersList: [...aa] }
  //   }

  //   return { ...oldUsers, usersList: [...newUserList] }
  // });
};

export const setOffsetState = offset => {
  setGlobalState('users', oldUsers => ({
    ...oldUsers,
    offset: offset
  }));
};

export const setCurrentPageState = currentPage => {
  setGlobalState('users', oldUsers => ({
    ...oldUsers,
    currentPage: currentPage,
  }));
};

export const setCountState = count => {
  setGlobalState('users', oldUsers => ({
    ...oldUsers,
    count: count,
  }));
};
// export const fetchMoreUsersState = fetchMore => {
//   setGlobalState('users', oldUsers => {
//     const offset = oldUsers.offset + USERS_LIMIT;
//     fetchMore(offset);
//     return { ...oldUsers, offset }
//   });
// };

export const setSearchUsersState = users => {
  const usersList = users?.result || [];
  setGlobalState('users', oldUsers => ({
    ...oldUsers,
    usersList: [...usersList],
  }));
};

export const setSearchState = search => {
  setGlobalState('users', oldUsers => ({
    ...oldUsers,
    search_string: search || '',
  }));
};

export const changeUserFiledState = (id, fieldName, value) => {
  setGlobalState('users', oldUsers => {
    const newUserList = [...(oldUsers.usersList || [])];
    const userIndex = newUserList.findIndex(user => user.id === id);
    if (userIndex !== -1) {
      newUserList[userIndex] = { ...newUserList[userIndex], [fieldName]: value };
    }

    return { ...oldUsers, usersList: [...newUserList] }
  });
};