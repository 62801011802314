import React from 'react';
import './Button.css';
import { buttonColors, buttonSizes } from "../../constants/buttons";

export const Button = ({
  text, onClick, size, color, isDisabled = false,
}) => {
  const checkButtonSize = buttonSizes[size] ? buttonSizes[size] : buttonSizes.medium;
  const checkButtonColor = buttonColors[color] ? buttonColors[color] : null;

  return (
    <button
      className={ `btn ${ checkButtonSize } ${ checkButtonColor }` }
      onClick={ onClick }
      disabled={ isDisabled }
    >
      { text }
    </button>
  );
};
