import React, { useState } from "react";
import Modal from 'react-modal';
import { useGlobalState } from "../../state/state";
import './AddNewKeyPopup.css';
import Input from "../Input/Input";
import { Button } from "../Button/Button";
import { postSettingsAction } from "../../api/actions";
import { buttonColors, buttonSizes } from "../../constants/buttons";
import Section from "../Section/Section";
import { closeAddNewKeyPopup } from "../../state/addNewKeyPopup";
// import { setInitialSettingsState } from "../../state/settingsState";
// import { setSettingsState } from "../../state/settingsState";
 
const AddNewKeyPopup = () => {

  const [{ show }] = useGlobalState('settingsPopup');

  const [key, setKey] = useState();
  const [value, setValue] = useState();

  const handleClickAdd = () => {
    const data = { settings:[{[key] : value }]};
    postSettingsAction(data)
      .then(response => {
        // setSettingsState()
        closeAddNewKeyPopup()
      })
      .catch(error => {
        console.error('error', error);
      });

  }
  return (

    <Modal
    isOpen={ show }
    onRequestClose={ () => {
      closeAddNewKeyPopup();
      setKey('');
      setValue('')
      }
    }
    ariaHideApp={ false }
    className="modal settingsPopup"
    overlayClassName="modal-overlay"
    >
      <Section>
            <div className="section-children section-children--settings">
              <p className="section-firstItem section-firstItem--settings">Key:</p>
              <div className="section-secondItem settings">
                <Input value={key} onChange={(e) => setKey(e.target.value)} />
              </div>
            </div>
            <div className="section-children">
              <p className="section-firstItem section-firstItem--settings">Value:</p>
              <div className="section-secondItem settings">
                <Input value={value} onChange={(e) => setValue(e.target.value)} />
              </div>
            </div>
      </Section>
      <Button size={buttonSizes.large} text='Add' color={buttonColors.blue} onClick={() => handleClickAdd()} />
      <span className="modal-close modal-close--settings" onClick={ () => {
            closeAddNewKeyPopup();
            setKey('');
            setValue('')
          } }>
        x close
      </span> 
    </Modal>
  )
};
 
export default AddNewKeyPopup;