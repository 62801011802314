import { setGlobalState, initialState } from './state';
import { apiTools } from "../api/apiTools";

export const setAuthState = token => {
    setGlobalState('isAuth', () => true);
    apiTools.setToken(token);
  };
  
export const logout = () => {
    setGlobalState('isAuth', () => false);
    setGlobalState('users', () => initialState.users);
    setGlobalState('popup', () => initialState.popup);
    setGlobalState('contracts', () => initialState.contracts);
    setGlobalState('counters', () => initialState.counters);
    setGlobalState('carOrderUsers', () => initialState.carOrderUsers);
    setGlobalState('changePricePopup', () => initialState.changePricePopup);
    setGlobalState('settings', () => initialState.settings);
    setGlobalState('settingsPopup', () => initialState.settingsPopup);
    setGlobalState('navbar', () => initialState.navbar);
    apiTools.removeToken();
};