import React from 'react';
import Section from '../Section/Section';
import { FaBars, FaTimes } from 'react-icons/fa';
import { useGlobalState } from '../../state/state';
import { closeNavbar, openNavbar } from '../../state/navbarState';
import { routes } from '../../constants/routes';
import { Link } from 'react-router-dom';
import './Menu.css';

export const Menu = () => {

    const [{ show }] = useGlobalState('navbar');

    return (
        <Section>
            <div className="menu-wrapper">
                <div className="menu-icon">
                    {show ? <FaTimes onClick={()=>closeNavbar()} /> : <FaBars onClick={()=>openNavbar()} />}
                </div>
                <Link to={ routes.main } className="nav-title">Wordlex</Link>
            </div>
        </Section>
    )
}
