import React, { useEffect, useMemo, useState } from 'react';
import Section from '../../components/Section/Section';
import Header from './Header/Header.js';
import Title from '../../components/Title/Title';
import Table from '../../components/Table/Table';
import PageWrapper from '../../components/PageWrapper/PageWrapper';
import { tableTransactionsConfig } from "../../constants/tablesConfig";
import { useGlobalState } from "../../state/state";
import { openPopup } from '../../state/popupState';
import { openChangePricePopup } from '../../state/changePricePopupState';
import { setContractsState } from '../../state/mainPageState';
import { getContractsAction, getContractAction } from "../../api/actions";
import { buttonColors } from "../../constants/buttons";
import { modalStatus } from "../../constants/modalStatus";
 
const Main = () => {
  const [contracts] = useGlobalState('contracts');
  const [loadingButtons, setLoadingButtons] = useState({});

  const setLoadingToButton = (url, loading) => {
    setLoadingButtons(prevLoading => ({ ...prevLoading, [url]: loading }))
  };
 
  const handleGetTransaction = url => {
    setLoadingToButton(url, true);
    getContractAction(url)
      .then(response => {
        setLoadingToButton(url, false);
        openPopup({ message: response.message, status: modalStatus.success, link: response.tx_hash });
      })
      .catch(error => {
        console.error('error', error);
        setLoadingToButton(url, false);
        openPopup({ message: error.message, status: modalStatus.error, link: error.tx_hash });
      });
  };

  const handleChangeWdxPrice = (contract) => {
      setLoadingToButton(contract.handler, false);
      openChangePricePopup({
        contract: contract.contract_details,
        link: contract.contract_number,
        balance: contract.balance
      })
  }
 
  useEffect(() => {
    if (!contracts) {
      getContractsAction()
        .then(setContractsState)
        .catch(error => {
          console.error('error', error);
        });    
    }
  }, []);
 
  const mapContractsToTable = useMemo(() => () =>{
    return contracts.map(contract => {
      return [
        { text: contract.contract_number, href: contract.contract_number },
        { 
          text: contract.contract_details,
          color: buttonColors.transparent,
          isDisabled: contract.wdx_eth === true ? false : true,
          onClick: () => handleChangeWdxPrice(contract)
        },
        { value: contract.balance },
        {
          text: 'Get',
          onClick: () => handleGetTransaction(contract.handler),
          color: buttonColors.blue,
          isDisabled: loadingButtons[contract.handler],
        },
      ]
    })
  }, [contracts, handleGetTransaction, loadingButtons]);
 
  const tableValues = contracts ? mapContractsToTable() : [];
 
  return (
    <PageWrapper>
      <Section><Header /></Section>
      <Section><Title title="Contracts" /></Section>
      <Table tableConfig={ tableTransactionsConfig } values={ tableValues } />
    </PageWrapper>
  )
};
 
export default Main;