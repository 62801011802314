import { api } from "./api";
import { apiTools } from "./apiTools";
 
export const getUsersAction = (data, params) => apiTools.get(api.getUsers, data, params );
 
export const searchUsersAction = (data, params) => apiTools.get(api.searchUsers, data, params );
 
export const signInAction = data => apiTools.post(api.login, data);
 
export const getCountersAction = () => apiTools.get(api.getCounters);
 
export const updateUserAction = (id, data) => apiTools.put(api.updateUser(id), data);
 
export const getContractsAction = () => apiTools.get(api.getContracts);
 
export const getContractAction = url => apiTools.post(api.getContract(url));
 
export const getCarOrderAction = (data, params) => apiTools.get(api.getCarOrders, data, params);
 
export const updateCarOrderUsersAction = (id, data) => apiTools.put(api.updateCarOrderStatus(id), data);
 
export const searchCarOrdersAction = (data, params) => apiTools.get(api.searchCarOrders, data, params);

export const postWdxPriceAction = data => apiTools.post(api.postWdxPrice, data);

export const getSettingsAction = (data, params) => apiTools.get(api.getSettings, data, params);

export const postSettingsAction = data => apiTools.post(api.postSettings, data);

export const deleteSettingsAction = data => apiTools.delete(api.deleteSettings, data);

export const getExchangeRatesAction = url => apiTools.get(api.getExchangeRates(url));