import { createGlobalState } from 'react-hooks-global-state';
import { modalStatus } from "../constants/modalStatus";

export const USERS_LIMIT = 50;

export const initialState = {

  isAuth: !!localStorage.getItem('jwt_token'),
  popup: {
    show: false,
    status: modalStatus.success,
    message: '',
    link: '',
  },
  changePricePopup: {
    show: false,
    link: '',
    contract: '',
    balance: '',
    eth: '',
    usdt: '',
    rub: '',
    wdxPriceEth: '',
    wdxPriceUsdt: '',
    wdxPriceRub: ''
  },
  users: {
    usersList: null,
    limit: USERS_LIMIT,
    offset: 0,
    search_string: '',
    perPage: 50,
    currentPage: 0,
    count: 0,
  },
  contracts: null,
  counters: {
    usersTotal: 0,
    stakingHolders: 0,
    totalStakingAmount: 0,
  },
  carOrderUsers: {
    usersList: null,
    limit: USERS_LIMIT,
    offset: 0,
    search_string: '',
  },
  settings: {
    settingsList: null,
    limit: USERS_LIMIT,
    offset: 0
  },
  settingsPopup: {
    show: false
  },
  navbar: {
    show: true
  }
};

const { setGlobalState, useGlobalState } = createGlobalState(initialState);

export { setGlobalState };
export { useGlobalState };