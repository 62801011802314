import React from 'react';
import Input from '../Input/Input';
import './SearchInput.css'
 
const SearchInput = ({value, placeholder, onChange, onFocus}) => {
    return (
        <div className="search-input">
            <Input value={ value } placeholder={placeholder} onChange={ onChange } onFocus={ onFocus }/>
        </div>
    )
}
 
export default SearchInput