import React from "react";
import './Select.css';

const Select = ({ options = [], value, onChange, onFocus}) => {
  return (
    <select onChange={ onChange } value={ value } onFocus={onFocus}>
      { options.map(option => (
        <option key={ option.value } value={ option.value }>
          { option.label }
        </option>
      )) }
    </select>
  )
};

export default Select;
