import { initialState, setGlobalState } from './state';

export const openAddNewKeyPopup = data => {
    setGlobalState('settingsPopup', oldPopup => ({ ...oldPopup, show: true }));
};
  
export const closeAddNewKeyPopup = () => {
    setGlobalState('settingsPopup', () => ({ ...initialState.settingsPopup }));
};

