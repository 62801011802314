import React from 'react';
import './App.css';
import { Redirect, Route, Switch } from "react-router-dom";

import Main from './pages/Main/Main';
import Navbar from './components/Navbar/Navbar';
import Popup from "./components/Popup/Popup";
import Users from './pages/Users/Users';
import Login from './pages/Login/Login';
import CarOrder from './pages/CarOrder/CarOrder';
import ChangePricePopup from './components/ChangePricePopup/ChangePricePopup';
import AddNewKeyPopup from './components/AddNewKeyPopup/AddNewKeyPopup';
import Settings from './pages/Settings/Settings';

import { routes } from "./constants/routes";
import { useGlobalState } from "./state/state";

const App = () => {
  const [isAuth] = useGlobalState('isAuth');

  return (
    <div className={ `${ isAuth ? 'row' : 'row-full' }` }>
      { isAuth && <Navbar /> }
      { isAuth ? (
        <Switch>
          <Route exact path={ routes.main } component={ Main } />
          <Route exact path={ routes.users } component={ Users } />
          <Route exact path={ routes.carOrder } component={ CarOrder } />
          <Route exaxt path={routes.settings} component={Settings} />
          <Redirect to={ routes.main } />
        </Switch>
      ) : (
        <Switch>
          <Route exact path={ routes.login } component={ Login } />
          <Redirect to={ routes.login } />
        </Switch>
      )}
      <Popup />
      <ChangePricePopup />
      <AddNewKeyPopup />
    </div>
  );
};

export default App;
