import React, { useRef } from 'react';
import { searchCarOrdersAction } from "../../../api/actions";
import { useGlobalState } from "../../../state/state";
import { setSearchCarOrdersUsersState, setCarOrderSearchState } from "../../../state/carOrderState";
import SearchInput from '../../../components/SearchInput/SearchInput';
 
const CarOrdersSearch = ({setNoUsersSearched}) => {
  const [{ search_string }] = useGlobalState('carOrderUsers');
  const searcher = useRef();
  const data = null;
  
  const handleInputChange = event => {
    const value = event.target.value.trim();
    setCarOrderSearchState(value);

    if (value.length === 0) {
      setNoUsersSearched(false);
    }
    else {
    clearTimeout(searcher.current);
    searcher.current = setTimeout(() => {
        searchCarOrdersAction(data, { ...(value && { search_string: value }) })
        .then(response => {
          if(response.result.length === 0){
            setNoUsersSearched(true);
          }
          else{
            setNoUsersSearched(false);
            setSearchCarOrdersUsersState(response);
          }
        }
          )
        .catch(error => {
          console.error('error', error);
        });
    }, 500);
  }
  };
 
  return <SearchInput onFocus={(e)=> e.target.value = ""} value={ search_string } placeholder="Username, email, address search..." onChange={ handleInputChange } />
 
}
 
export default CarOrdersSearch;