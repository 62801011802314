import React from 'react';
import './PageWrapper.css';
import { Menu } from '../Menu/Menu';

const PageWrapper = ({ children, containerRef }) => (
  <div ref={ containerRef } className="wrapper">
    <Menu />
    { children }
  </div>
);

export default PageWrapper;
