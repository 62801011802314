export const buttonColors = {
  green: 'green',
  blue: 'blue',
  transparent: 'transparent',
  red: 'red'
};

export const buttonSizes = {
  medium: 'btn--medium',
  large: 'btn--large',
};
