import { setGlobalState } from './state';

export const setCountersState = counters => {
    const { staking_holders, total_staking_amount, total_users } = counters?.result || {};
    setGlobalState('counters', () => ({
      usersTotal: total_users,
      stakingHolders: staking_holders,
      totalStakingAmount: total_staking_amount,
    }));
  };

export const setContractsState = contracts => {
    const contractsList = contracts?.result || [];
    setGlobalState('contracts', () => contractsList);
};  