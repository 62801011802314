import React from 'react';
import { Link, useHistory, useLocation } from 'react-router-dom';
import './Navbar.css';
import { routes } from "../../constants/routes";
import { Button } from "../Button/Button";
import { buttonColors } from "../../constants/buttons";
import { logout } from "../../state/authState";
import { useGlobalState } from '../../state/state';

const Navbar = () => {
  const [{ show }] = useGlobalState('navbar');
  const { pathname } = useLocation();
  const history = useHistory();

  const handleLogout = () => {
    logout();
    history.push(routes.login);
  };

  return (
    <div className={`navbar ${ show? 'navbar-show': 'navbar-hide' }` }>
      <div className="nav-header">
        <Button color={ buttonColors.blue } onClick={ handleLogout } text='Logout' />
      </div>

      <Link className={ `nav-item ${ pathname === routes.main ? 'nav-item--active' : '' }` } to={ routes.main }>
        Main
      </Link>
      <Link className={ `nav-item ${ pathname === routes.users ? 'nav-item--active' : '' }` } to={ routes.users }>
        Users
      </Link>
      <Link className={ `nav-item ${ pathname === routes.carOrder ? 'nav-item--active' : '' }` } to={ routes.carOrder }>
        Car orders
      </Link>
      <Link className={ `nav-item ${ pathname === routes.settings ? 'nav-item--active' : '' }` } to={ routes.settings }>
        Settings
      </Link>
    </div>
  )
};

export default Navbar;
