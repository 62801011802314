import React, { useRef } from 'react';
import { searchUsersAction } from "../../../api/actions";
import { useGlobalState } from "../../../state/state";
import { setSearchUsersState, setSearchState } from "../../../state/usersState";
import SearchInput from '../../../components/SearchInput/SearchInput';

function UsersSearch({ loadUsers, setNoUsersSearched }) {
  const [{ search_string, perPage, currentPage }] = useGlobalState('users');
  const searcher = useRef();
  const data = null;

  const handleInputChange = event => {
    const value = event.target.value.trim();
    setSearchState(value);
    if (value.length === 0) {
      const newOffset = currentPage * perPage;
      loadUsers(newOffset);
      setNoUsersSearched(false);
    }
    else {
      // такая мини реализация debounce
      clearTimeout(searcher.current);
      searcher.current = setTimeout(() => {
        searchUsersAction(data, { ...(value && { search_string: value }) })
          .then(response => {
            if(response.result.length === 0){
              setNoUsersSearched(true);
            }
            else{
              setNoUsersSearched(false);
              setSearchUsersState(response);
            }
          }
          )
          .catch(error => {
            console.error('error', error);
          });
      }, 500);
    }
  };

  return <SearchInput value={search_string} placeholder="Username, email, address search..." onChange={handleInputChange} />
}

export default UsersSearch;