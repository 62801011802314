import React, { useEffect, useMemo, useRef, useState } from 'react';
import Section from '../../components/Section/Section';
import PageWrapper from '../../components/PageWrapper/PageWrapper';
import Table from '../../components/Table/Table';
import Title from '../../components/Title/Title';
import UsersSearch from './Search/UsersSearch';
import { useGlobalState } from "../../state/state";
import { openPopup } from '../../state/popupState';
import { setUsersState, changeUserFiledState, setOffsetState, setCurrentPageState, setCountState } from '../../state/usersState';
import { getUsersAction, updateUserAction } from "../../api/actions";
import { tableUsersConfig } from "../../constants/tablesConfig";
import { userStatuses } from "../../constants/userStatuses";
import { buttonColors } from "../../constants/buttons";
import { modalStatus } from "../../constants/modalStatus";
import ReactPaginate from 'react-paginate';
import './Users.css';

const Users = () => {
  const [{ usersList, limit, offset, perPage, currentPage, count }] = useGlobalState('users');
  const [loadingButtons, setLoadingButtons] = useState({});
  // лоадер пользователей при подгрузке новой пачки юзеров,
  // нужно, чтобы не вызывалась множественная подгрузка, потому что событие на скролл
  // может вызываться много раз
  const isLoadingUsers = useRef(false);
  // флаг показывает, что уже все пользователи подгружены и больше подгружать не надо
  const isAllUsersLoaded = useRef(false);
  const containerRef = useRef();

  const [noUsersSearched, setNoUsersSearched] = useState(false);
 
  const newData = null;
  const setLoadingToButton = (id, loading) => {
    setLoadingButtons(prevLoading => ({ ...prevLoading, [id]: loading }))
  };
  const postData = () => <Table tableConfig={tableUsersConfig} values={tableValues} />

  const loadUsers = (newOffset) => {
    getUsersAction(newData, { limit, offset: newOffset })
      .then(response => {
        isLoadingUsers.current = false;
        const data = response.result;
        setUsersState(data);
        if (response?.extra?.count) {
          setCountState(Math.ceil(response.extra.count / perPage));
        }
        window.scrollTo(0, 0);

        if (!response?.result.length) {
          isAllUsersLoaded.current = true;
        }
      })
      .catch(error => {
        isLoadingUsers.current = false;
        console.error('error', error);
      });
  };

  const handlePageClick = ({ selected: selectedPage }) => {
    // const selectedPage = e.selected;
    setCurrentPageState(selectedPage);
    const newOffset = selectedPage * perPage;
    setOffsetState(newOffset);
  };

  const handleChangeStatus = (event, id) => {
    changeUserFiledState(id, 'status', event.target.value);
  };

  const handleChangeRefererId = (event, id) => {
    changeUserFiledState(id, 'ref_user_id', event.target.value);
  };

  const handleChangeWdx = (event, id) => {
    changeUserFiledState(id, 'wdx_withdrawal_allow', event.target.checked);
  };

  const handleClickSave = (event, id) => {
    const currentUser = usersList.find(user => user.id === id);
    if (currentUser) {
      const { ref_user_id, status, wdx_withdrawal_allow } = currentUser;
      const userData = { ref_user_id: ref_user_id || null, status, wdx_withdrawal_allow };
      setLoadingToButton(id, true);
      updateUserAction(id, userData)
        .then(response => {
          setLoadingToButton(id, false);
          openPopup({ message: response.message, status: modalStatus.success, link: response.tx_hash });
        })
        .catch(error => {
          console.error('error', error);
          setLoadingToButton(id, false);
          openPopup({ message: error.message, status: modalStatus.error, link: error.tx_hash });
        });
    }
  };

  const mapUsersToTable = useMemo(() => () => {
    return usersList.map(user => {
      return [
        { value: user.id },
        { value: user.username },
        { text: user.address, href: user.address },
        { options: userStatuses, value: user.status, onChange: event => handleChangeStatus(event, user.id) },
        { placeholder: 'Referer ID...', onChange: event => handleChangeRefererId(event, user.id), value: user.ref_user_id },
        { onChange: event => handleChangeWdx(event, user.id), checked: user.wdx_withdrawal_allow },
        {
          text: 'Save',
          onClick: event => handleClickSave(event, user.id),
          color: buttonColors.green,
          isDisabled: loadingButtons[user.id],
        },
      ]
    })
  }, [usersList, handleChangeStatus, handleChangeRefererId, handleChangeWdx, handleClickSave, loadingButtons]);

  useEffect(() => {
    loadUsers(offset);
  }, [offset]);


  const tableValues = usersList ? mapUsersToTable() : [];

  return (
    <PageWrapper containerRef={containerRef}>
      <Section><Title title="User status" /></Section>
      <Section><UsersSearch loadUsers={loadUsers} setNoUsersSearched={setNoUsersSearched} /></Section>

      {noUsersSearched ?
        <div>По условиям поиска ничего не найдено</div>
        :
        <>
          {postData()}
          <ReactPaginate
            previousLabel={"←"}
            nextLabel={"→"}
            breakLabel={"..."}
            breakClassName={"break-me"}
            pageCount={count}
            marginPagesDisplayed={2}
            pageRangeDisplayed={5}
            onPageChange={(pageNumber) => handlePageClick(pageNumber)}
            containerClassName={"pagination"}
            subContainerClassName={"pages pagination"}
            forcePage={currentPage}
            activeClassName={"active"} />
        </>
      }

    </PageWrapper>
  )
};

export default Users;