import React from 'react';
import './Table.css';
import { tableCellTypes } from "../../constants/tablesConfig";
import CustomLink from "../CustomLink/CustomLink";
import Select from "../Select/Select";
import Input from "../Input/Input";
import Checkbox from "../Checkbox/Checkbox";
import { Button } from "../Button/Button";
import { useGlobalState } from '../../state/state';

function Table({ tableConfig, values = []}) {
 
  const [{ show }] = useGlobalState('navbar');
 
  const renderContent = (content, i) => {
    switch (tableConfig[i].type) {
      case tableCellTypes.text:
          return <div className="cell" key={ i }>{content.value}</div>;
      case tableCellTypes.link:
        return <div className="cell" key={ i }><CustomLink { ...content } /></div>;
      case tableCellTypes.select:
        return <div className="cell" key={ i }><Select { ...content } /></div>;
      case tableCellTypes.input:
        return <div className="cell" key={ i }><Input { ...content } /></div>;
      case tableCellTypes.checkbox:
        return <div className="cell" key={ i }><Checkbox { ...content } /></div>;
      case tableCellTypes.button:
        return <div className="cell" key={ i }><Button { ...content } /></div>;
      default:
        return null;
    }
    
  };
 
  return (
    <div className={ `table-wrapper table-wrapper-${ tableConfig.length } ${ show === false && `table-wrapper-${ tableConfig.length }--menu-close` }` }>
      { tableConfig.map(headCell => (
        <div key={ headCell.title } className="cell table-head-cell">{ headCell.title }</div>
      )) }
      { values.map(content => content.map(renderContent)) }
    </div>
  )
}
 
export default Table